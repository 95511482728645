import React from 'react'
import { graphql, useStaticQuery, Link} from "gatsby"

import styles from './about.module.css'
import Expertise from './expertise'

const About = () => {
	const query = useStaticQuery(graphql`
	query {
		strapiAboutBasicContentManager {
		    title
		    sub_title
		    short_description
		    our_vision {
		      description
		      icon {
		        publicURL
		      }
		    }
		     banner_image {
		      childImageSharp {
		        fluid {
		          src
		        }
		      }
		    }
		  }
	  }
	`)
	let about = query.strapiAboutBasicContentManager
	return (
		<div className={styles.container}>
			<div className={styles.bannerWrapper}>
				<div className={styles.bannerContent}>
					<h2>Car locksmith</h2>
					<div dangerouslySetInnerHTML={{__html: about.sub_title}}/>
					<div dangerouslySetInnerHTML={{__html: about.short_description}}/>
				</div>
				<div className={styles.bannerImage}>
					<img src={about.banner_image.childImageSharp.fluid.src} alt=""/>
				</div>
			</div>
			<div className={styles.visionWrapper}>
				<h2>Our vision</h2>
				<div className={styles.visionItems}>
					{
						about.our_vision.map((item, index)=>{
							return(
								<div className={styles.visionItem} key={index}>
									<img src={item.icon.publicURL} alt=""/>
									<p>{item.description}</p>
								</div>
							)
						})
					}
				</div>
			</div>
			<div className={styles.expertiseWrapper}>
				<h2>Our area of expertise:</h2>
				<Expertise styles={styles}/>
			</div>
		</div>
	)
}

export default About