import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DetailsHead from "../components/detailshead"
import AboutUs from '../components/about/about-page/index'

const About = () => (
  <Layout>
    <SEO title="About Us: About Car LockSmith Services" />
    <DetailsHead title="About us"/>
    <AboutUs/>
  </Layout>
)

export default About
